import "semantic-ui-css/semantic.min.css";
import "./styles/kendo.scss";
import "./styles/faceted-filter.scss";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.scss";

import React, { useContext } from "react";

import AppRoutes from "./AppRoutes";
import AuthError from "./components/AuthError";
import { IIQContext } from "./components/IIQContextProvider";
import IdleTimeout from "@itm21st/idle-timeout-react";
import { PortfolioProvider } from "./components/PortfolioProvider";
import SimpleLoader from "./components/SimpleLoader";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

const App: React.FC = () => {
    const { logout, isLoading, error } = useAuth0();
    const { appData } = useContext(IIQContext);

    if (isLoading) {
        return <SimpleLoader />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <PortfolioProvider>
            <IdleTimeout onIdle={() => logout({ returnTo: appData?.organization.SAMLLogoutUrl ?? undefined })} />
            <ToastContainer />
            <AppRoutes />
        </PortfolioProvider>
    );
};

export default App;
